/* FADE UP KEYFRAMES */
@-webkit-keyframes fadeUp {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeUp {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeUp {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeUp {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeUp {
  -webkit-animation-name: fadeUp;
  -moz-animation-name: fadeUp;
  -ms-animation-name: fadeUp;
  -o-animation-name: fadeUp;
  animation-name: fadeUp;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -ms-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -ms-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

.fadeUpFast {
  -webkit-animation-name: fadeUp;
  -moz-animation-name: fadeUp;
  -ms-animation-name: fadeUp;
  -o-animation-name: fadeUp;
  animation-name: fadeUp;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  -ms-animation-duration: 0.4s;
  -o-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -ms-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

@-webkit-keyframes fadeUpConstant {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeUpConstant {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeUpConstant {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeUpConstant {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeUpConstant {
  -webkit-animation-name: fadeUpConstant;
  -moz-animation-name: fadeUpConstant;
  -ms-animation-name: fadeUpConstant;
  -o-animation-name: fadeUpConstant;
  animation-name: fadeUpConstant;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -ms-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -ms-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* FADE IN ANIMATION */
@-webkit-keyframes fadeIn {
  0% {
    filter: opacity(10%);
  }
  100% {
    filter: opacity(100%);
  }
}
@-moz-keyframes fadeIn {
  0% {
    filter: opacity(10%);
  }
  100% {
    filter: opacity(100%);
  }
}
@-ms-keyframes fadeIn {
  0% {
    filter: opacity(10%);
  }
  100% {
    filter: opacity(100%);
  }
}
@keyframes fadeIn {
  0% {
    filter: opacity(10%);
  }
  100% {
    filter: opacity(100%);
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -ms-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-delay: 0.15s;
  -moz-animation-delay: 0.15s;
  -ms-animation-delay: 0.15s;
  -o-animation-delay: 0.15s;
  animation-delay: 0.15s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}
