.root-dark {
  min-height: 100vh;
  background-color: black;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 24px;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* FOR IF TOO SMALL */
  /* transform: scale(1.1);
  transform-origin: 0 0; */
}

code {
  /*background-color: rgba(68, 203, 255, 0.5);*/
  background-color: rgba(87, 115, 146, 0.4);
  font-size: 18px;
  padding: 0 5px;
  font-family: "Dank Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

@media only screen and (max-width: 768px) {
  code {
    font-size: 16px;
  }
}

code.code-block {
  box-sizing: border-box;
  display: block;
  white-space: pre-wrap;
  width: 100%;
  padding: 15px 5px;
  padding-left: 15px;
  border-radius: 10px;
}

a {
  text-decoration: underline;
  color: #708aff;
}

a:visited {
  color: #708aff;
}

li {
  margin-bottom: 8px;
  line-height: 1.3;
}

/** For Animations **/
.song-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 25%;
  display: flex;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.song-details-dark {
  background-color: #1b1b1b;
  box-shadow: none;
}

.main-song-div {
  width: 45vw;
}

.spotify-closed-widget {
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 20px 25%;
  border-radius: 5px;
}

.spotify-closed-widget:hover {
  background-color: #f1f1f1;
  transition: 0.3s;
}

.spotify-closed-widget-dark:hover {
  background-color: #222222;
}

.bg-dark {
  transition: 0.3s;
}

.bg-light {
  transition: 0.3s;
}

@media screen and (max-width: 1180px) {
  .song-details,
  .spotify-closed-widget {
    padding: 20px 15%;
  }
  .main-song-div {
    width: 60vw;
  }
}

@media screen and (max-width: 768px) {
  .song-details,
  .spotify-closed-widget {
    padding: 20px 5%;
  }
  .main-song-div {
    width: 70vw;
  }
}

::selection {
  background: #91a6ff;
}
::-moz-selection {
  background: #91a6ff;
}
